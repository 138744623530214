import React from "react"
import Layout from "../components/layout"
import { Link, graphql } from "gatsby"
import SEO from "../components/seo"
import { ArrowUpRight, ArrowRight } from "react-feather"
import styles from "./work.module.css"
// import classNames from "classnames/bind"

export default ({ data }) => {
  const caseStudies = data.allMarkdownRemark.edges.filter(
    ({ node }) => node.frontmatter.posttype === "case-study"
  )
  return (
    <Layout>
      <SEO title="Work" />

      <div className={styles.introContainer}>
        <h1 className={styles.staticTitle}>
          Our <br />
          Work
        </h1>
        <p className={styles.staticSubTitle}>
          We help companies and startups to build efficient digital products
          with a human-first approach through proper UX & interaction design.
        </p>
      </div>
      <div className={styles.container}>
        {caseStudies.map(({ node }) => {
          const {
            title,
            featuredImage,
            featuredVideo,
            bgColor,
            color,
            description,
            text,
            tags,
            linkURL,
            hasCaseStudy,
          } = node.frontmatter

          const tagList = tags
            .split(",")
            .map(tag => <li key={node.id + `${tag}`}>{tag}</li>)

          let caseLink
          if (hasCaseStudy || !linkURL) {
            caseLink = (
              <Link
                className={styles.caseLink}
                to={node.fields.slug}
                style={{ borderColor: `${color}` }}
              >
                View Case Study <ArrowRight />
              </Link>
            )
          } else {
            caseLink = (
              <a
                className={styles.webLink}
                href={linkURL}
                target="_blank"
                rel="noopener noreferrer"
                style={{ borderColor: `${color}` }}
              >
                View Website <ArrowUpRight />
              </a>
            )
          }

          return (
            <div className={styles.caseContainer} key={node.id}>
              <div
                className={styles.caseInfo}
                style={{
                  backgroundColor: `${bgColor}`,
                  color: `${color}`,
                }}
              >
                <h3 className={styles.caseTitle}>{title}</h3>
                <p className={styles.caseDescription}>{description}</p>
                <p className={styles.caseText}>{text}</p>
                <div
                  className={styles.caseDivider}
                  style={{ backgroundColor: `${color}` }}
                />
                <ul>{tagList}</ul>
                {caseLink}
              </div>
              <div className={styles.caseImageContainer}>
                {featuredVideo ? (
                  <video
                    src={featuredVideo}
                    autoPlay
                    muted
                    className={styles.caseImage}
                    loop
                    playsInline
                  />
                ) : (
                  <img
                    className={styles.caseImage}
                    src={featuredImage}
                    alt=""
                  />
                )}
              </div>
            </div>
          )
        })}
      </div>
    </Layout>
  )
}
export const query = graphql`
  query {
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      totalCount
      edges {
        node {
          id
          frontmatter {
            title
            date(formatString: "DD MMMM, YYYY")
            posttype
            featuredImage
            bgColor
            color
            description
            text
            tags
            linkURL
            hasCaseStudy
          }
          fields {
            slug
          }
          excerpt
        }
      }
    }
  }
`

//TODO: Add featuredVideo back in to graphql when needed (it errors if none of the markdown files actually use a video)
